import React from "react";
import { useSelector } from "react-redux";

const Blog = () => {
  const list = useSelector((state) => state.list.list);
  const contentItem = list.find((item) => item.typeId === 5); // Assuming 2 is the typeId for Terms and Conditions

  if (!contentItem) return <p>No content available</p>;

  return (
    <div className="container mt-4">
      <h1>{contentItem.title}</h1>
      <hr
        className='mb-4'
        style={{
          width:"12%",
          position:"relative",
          height:"5px",
          border:"0",
          backgroundColor:"#ff5a00",
          opacity:"1"
        }}
      ></hr>
      <div dangerouslySetInnerHTML={{ __html: contentItem.content }} />
    </div>
  );
};

export default Blog;
