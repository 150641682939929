import React, { useState, useRef, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import "./style.css";

const MyMap = ({ onConfirmLocation, show, onHide, latlng }) => {
  const [markerPosition, setMarkerPosition] = useState(latlng);
  const [address, setAddress] = useState("");
  const mapRef = useRef(null);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyDx76zIg1GtntGYfyeGm_sOdBwsO30TiGE", // Replace with your Google Maps API key
    libraries: ["places"],
  });

  const updateMarkerPosition = (latLng) => {
    setMarkerPosition(latLng);
    if (mapRef.current) {
      mapRef.current.panTo(latLng); // Pan the map to the new marker position
    }
  };

  const handleSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      updateMarkerPosition(latLng);
    } catch (error) {
      console.error("Error fetching location:", error);
    }
  };

  if (!isLoaded) return <div>Loading...</div>;
  if (loadError) return <div>Error loading map</div>;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Click on Map to Set Your Location</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ position: "relative" }}>
          <GoogleMap
            center={markerPosition}
            zoom={8}
            mapContainerStyle={{ height: "400px", width: "100%" }}
            onLoad={(map) => (mapRef.current = map)}
            onClick={(e) =>
              updateMarkerPosition({
                lat: e.latLng.lat(),
                lng: e.latLng.lng(),
              })
            }
          >
            <Marker position={markerPosition} />
          </GoogleMap>
          <div className="autocomplete-container search-container text-center">
            <PlacesAutocomplete
              value={address}
              onChange={setAddress}
              onSelect={handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <input
                    {...getInputProps({
                      placeholder: "Search Places...",
                      className: "location-search-input",
                    })}
                  />
                  {loading && <div>Loading...</div>}
                  {suggestions.length > 0 && (
                    <div className="autocomplete-dropdown-container">
                      {suggestions.map((suggestion, index) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                            })}
                            key={index}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
            </PlacesAutocomplete>
          </div>
          <div className="text-end">
            <button
              onClick={() => onConfirmLocation(markerPosition)}
              className="btn btn-block btn-info m-2"
            >
              Confirm Location
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MyMap;
